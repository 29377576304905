import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, afterNextRender } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { URL_SERVICEIO } from '../../../config/config';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string='';
  user:any;
  constructor(
    public http:HttpClient,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.initAuth();
  }
  initAuth() {
    //if (typeof window !== 'undefined' && localStorage.getItem('token')) {
    if (isPlatformBrowser(this.platformId) && localStorage.getItem('token')) {
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;
      this.token = localStorage.getItem('token') || '';
    }
  }
  login(email:string, password:string){
    let url=URL_SERVICEIO+"/auth/login";
    return this.http.post(url,{email,password}).pipe(
      map((resp: any) => {
        const result=this.saveLocalStorage(resp);
        return result;
      }),
      catchError((err:any) =>{
        return of(err);
      })
    )
  }

  saveLocalStorage(resp:any){
    if (isPlatformBrowser(this.platformId) && resp && resp.access_token) {
      localStorage.setItem("user",JSON.stringify(resp.user));
      localStorage.setItem("token", resp.access_token);
      return true;
    }
    return false;
  }

  register(data:any){
    let url=URL_SERVICEIO+"/auth/register";
    return this.http.post(url, data);
  }
  verifiedAuth(data:any){
    let url=URL_SERVICEIO+"/auth/verifiedAuth";
    return this.http.post(url, data);
  }
  verifiedMail(data:any){
    let url=URL_SERVICEIO+"/auth/forgot_password";
    return this.http.post(url, data);
  }
  verifiedCode(data:any){
    let url=URL_SERVICEIO+"/auth/verifiedCodeForgot";
    return this.http.post(url, data);
  }
  verifiedNewPass(data:any){
    let url=URL_SERVICEIO+"/auth/newPassword";
    return this.http.post(url, data);
  }

  logout(){
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
    this.user=null;
    this.token="";
    setTimeout(() => {
      this.router.navigateByUrl("/login");
    }, 500);    
  }

  updateInfo(data:any){
    let headers = new HttpHeaders({'Authorization': 'Bearer '+this.token});
    let URL = URL_SERVICEIO+"/auth/updateInfo"; 
    //return this.http.post(URL,data,{headers: headers});

    return this.http.post(URL,data,{headers: headers}).pipe(
      map((resp: any) => {
        if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem("user",JSON.stringify(resp.user));
        }
        return true;
      }),
      catchError((err:any) =>{
        return of(err);
      })
    )
  }
  getOrder(page:number,user:number){
    let URL = URL_SERVICEIO+"/frontEnd/orders/user/"+user+"?page="+page;
    return this.http.get(URL); 
  }
}
